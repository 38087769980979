<script setup lang="ts">
const { backgroundColor = 'transparent', outerPadding = true, innerPadding = false, breakOut = false } = defineProps<{
  /**
   * Break out the background with a vertical padding?
   * NOTE/TODO: this does NOT break out full width. We need to add a new prop for that.
   * Are we still using this? Roos' redesign removed it.
   */
  // breakoutBackground?: boolean
  /**
   * The background color of the container.
   */
  backgroundColor?: 'white' | 'primary' | 'secondary' | 'muted' | 'transparent'
  /**
   * Apply the theme padding for content blocks or none?
   * Whith "theme" padding, we mean: use the padding that we have configured to be the default for content blocks.
   * However... the 'theme' padding is basically "inner" padding. Maybe we should differentiate between them.
   */
  outerPadding?: boolean

  /**
   * Apply padding to the inner content?
   */
  innerPadding?: boolean
  /**
   * Break out this component from the grid?
   * If false (the default), the container will be full width and have a max-width.
   */
  breakOut?: boolean
}>()

defineOptions({
  inheritAttrs: false,
})

// For now we asume that primar and secondary are dark colors, and white and muted are light colors.
const textColor = computed(() => {
  switch (backgroundColor) {
    case 'white':
      return 'text-black'
    case 'muted':
      return 'text-black'
    case 'transparent':
      return 'text-black'
    default:
      return 'text-white'
  }
})

const contentBlockPadding = inject<string>('contentBlockPadding', 'p-8 md:py-10')

const { cardRadiusClass } = useThemeClasses()

</script>

<template>
  <div
    class="mx-auto max-w-[calc(theme(maxWidth.7xl)-2rem)] md:max-w-7xl"
    :class="{
      'px-4': outerPadding,
    }"
  >
    <div
      v-bind="$attrs"
      class="relative dark:text-white"
      :class="{
        // Inner padding
        [contentBlockPadding]: innerPadding,
        // Card radius
        [cardRadiusClass]: true,
        // Text color
        [textColor]: true,
        // Background colors
        [`bg-${backgroundColor}${backgroundColor === 'white' ? '' : '-500'}`]: true,
        'dark:bg-muted-900': backgroundColor !== 'transparent',
      }"
    >
      <slot />
    </div>
  </div>
</template>
